@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-color: #1B191A;
  color: rgb(46, 40, 55);
  font-family: 'Maven Pro', sans-serif;

}

@font-face {
  font-family: 'Engramma';
  src: url('../Engramma.woff2') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Coco';
  src: url('../Cocogoose-Pro-Ultralight.woff2') format('truetype');
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

